@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html.dark body:not(.mobile) *::-webkit-scrollbar,
html.dark body:not(.mobile)::-webkit-scrollbar {
  background-color: rgb(32, 32, 32);
}

html.dark body:not(.mobile) *::-webkit-scrollbar-thumb,
html.dark body:not(.mobile)::-webkit-scrollbar-thumb {
  background-color: rgb(88, 88, 88);
  border-radius: 8px;
  border: 3px rgb(32, 32, 32) solid;
}

html body:not(.mobile) *::-webkit-scrollbar,
html body:not(.mobile)::-webkit-scrollbar {
  background-color: rgb(209, 207, 207);
}

html body:not(.mobile) *::-webkit-scrollbar-thumb,
html body:not(.mobile)::-webkit-scrollbar-thumb {
  background-color: rgb(184, 176, 176);
  border-radius: 8px;
  border: 3px rgb(209, 207, 207) solid;
}

.ng-invalid.ng-rouched .error-icon {
  display: block;
}
.ng-valid .error-icon {
  display: none;
}

html {
  width: 100vw;
  max-width: 100%;
  margin: 0;
  padding: 0;
  height: calc(var(--vh, 1vh) * 100);
}

body {
  font-family: 'Quicksand', sans-serif;
  font-size: 1rem;
  height: calc(var(--vh, 1vh) * 100);
  scroll-behavior: smooth;
}

body.modal-open {
  filter: none !important;
  overflow: hidden;
}

.ngx-datatable.material.fixed-header
  .datatable-header
  .datatable-header-inner
  .datatable-header-cell {
  @apply bg-box-light dark:bg-box-dark;
}

.ngx-datatable.material.material .column-header {
  @apply font-bold text-center text-body-dark dark:text-body-light;
  font-weight: 600 !important;
}
.ngx-datatable.material.material .datatable-header-cell-template-wrap {
  @apply font-bold text-center;
}

.ngx-datatable.material.fixed-row
  .datatable-scroll
  .datatable-body-row
  .datatable-body-cell {
  @apply text-body-dark dark:text-body-light;
}

.ngx-datatable.material .empty-row {
  @apply text-center text-body-dark dark:text-body-light;
}

.ngx-datatable.material .datatable-body-cell-label,
.ngx-datatable.material .page-count {
  @apply text-body-dark dark:text-body-light;
  font-size: 12px !important;
  font-weight: 600 !important;
}
.ngx-datatable.material .datatable-body-cell-label {
  @apply text-center;
}
.ngx-datatable.material .datatable-row-center .datatable-body-cell {
  @apply border dark:border-active-link-dark border-active-link;
  padding: 20px 0 !important;
}

.ngx-datatable.material .datatable-row-center .datatable-body-cell:first-child {
  border-left: none;
  border-right: none;
}
.ngx-datatable.material .datatable-row-center .datatable-body-cell:last-child {
  border-right: none;
  border-left: none;
}
.ngx-datatable.material .datatable-footer {
  display: none;
}

element.style {
  width: 993px;
}
.ngx-datatable.material .datatable-body-row:hover {
  @apply dark:bg-black;
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection)
  .datatable-body-row:hover
  .datatable-row-group {
  background-color: transparent;
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
:host ::ng-deep .ngx-datatable .datatable-body .datatable-scroll {
  display: inherit;
}

/* styles for ngb-modal */
ngb-modal-backdrop.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
}

ngb-modal-backdrop.modal-backdrop ~ ngb-modal-backdrop.modal-backdrop {
  background: none;
}

ngb-modal-window.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1100;
  @apply overflow-hidden bg-box-light dark:bg-box-dark sm:rounded-t-2xl;
}

ngb-modal-window.modal div.modal-dialog,
ngb-modal-window.modal div.modal-dialog div.modal-content {
  @apply h-full;
}

@media (min-width: 320px) and (max-width: 767px) {
  ngb-modal-window.general-modal div.set-goal-rewards-modal {
    margin: 0 !important;
    height: 100% !important;
    border-radius: 0 !important;
    max-height: 100% !important;
  }

  ngb-modal-window.general-modal div.set-goal-rewards-modal .modal-content {
    padding: 1rem !important;
    /* text-align: left!important; */
  }
}

@screen sm {
  ngb-modal-window.modal {
    top: 80px;
  }
}

@screen md {
  ngb-modal-window.modal {
    top: 80px;
    max-width: 1367px;
  }
}

.modal {
  position: absolute;
  overflow-y: auto;
  animation: 0.5s ease normal forwards 1 showTranslateY;
  min-height: 80%;
}

.modal.is-hiding {
  animation: 0.5s ease normal forwards 1 hideTranslateY;
}

.modal.is-skipping,
.modal.is-answering {
  animation: none;
}

@keyframes showTranslateY {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes hideTranslateY {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes showFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hideFade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

*:focus {
  outline: none;
  box-shadow: none;
}

#swal2-content {
  margin: 1rem 0;
  padding: 0 1rem;
}
.swal2-confirm .btn-confirm {
  height: 3.5rem !important;
}

/* General Modal */
ngb-modal-window.general-modal {
  @apply top-0 flex items-center w-full h-full align-middle bg-transparent opacity-100 place-content-center dark:bg-transparent;
  max-width: 100%;
  animation: none;
}

ngb-modal-window.general-modal div.modal-dialog {
  @apply w-full h-auto max-h-screen md:max-h-full  m-8 overflow-y-auto bg-body-light dark:bg-body-dark shadow-mediumModalShadow rounded-2xl;
  max-width: 500px;
}

ngb-modal-window.general-modal div.set-goal-rewards,
ngb-modal-window.general-modal div.set-goal-rewards-modal {
  @apply w-full h-auto max-h-screen md:max-h-full  mx-4 my-8 overflow-y-auto bg-body-light dark:bg-body-dark shadow-mediumModalShadow rounded-2xl;
  max-width: 558px;
}
ngb-modal-window.general-modal div.rewards-modal {
  @apply w-full h-auto max-h-screen md:max-h-full  mx-4 my-8 overflow-y-auto bg-body-light dark:bg-body-dark shadow-mediumModalShadow rounded-2xl;
  max-width: 481px;
}
ngb-modal-window.general-modal div.challenge-modal {
  @apply w-full h-auto max-h-screen md:max-h-full  mx-4 my-8 overflow-y-auto bg-body-light dark:bg-body-dark shadow-mediumModalShadow rounded-2xl;
  max-width: 471px;
}
ngb-modal-window.general-modal div.reachGoal-modal {
  @apply w-full h-auto max-h-screen md:max-h-full  mx-4 my-8 overflow-y-auto bg-body-light dark:bg-body-dark shadow-mediumModalShadow rounded-2xl;
  max-width: 620px;
}
/* ngb-modal-window.reachedGoal{
  animation: zoomIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards!important;
} */

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

ngb-modal-window.general-modal div.set-goal-rewards-modal div.modal-content {
  padding-right: 1.4rem !important;
  padding-left: 1.4rem !important;
}

ngb-modal-window.general-modal div.modal-dialog div.modal-content {
  @apply h-auto p-8 text-center;
}
ngb-modal-window.general-modal.specialMission div.modal-dialog {
  max-width: 550px;
}

ngb-modal-window.general-modal.specialMission
  div.modal-dialog
  div.modal-content {
  padding: 0 !important;
  background-color: #24232b !important;
  color: #fff !important;
}
ngb-modal-window.general-modal.specialMission
  div.modal-dialog
  div.modal-content
  .closeBtn
  svg
  path {
  fill: #fff !important;
}

ngb-modal-window.general-modal .modal-content .modal-header {
  @apply flex place-content-end;
}

ngb-modal-window.general-modal .modal-content .close-btn {
  @apply text-4xl text-right dark:text-body-light;
}

ngb-modal-window.general-modal .modal-content h4 {
  @apply mb-1 text-2xl font-bold dark:text-body-light;
}

ngb-modal-window.general-modal .modal-content h5 {
  @apply mb-6 text-lg font-semibold dark:text-body-light;
}

ngb-modal-window.general-modal .modal-content p {
  @apply mb-6 text-base font-normal dark:text-body-light;
}

ngb-modal-window.general-modal .modal-content .submit-btn {
  @apply relative text-base font-bold text-white rounded-full w-52 h-14 bg-blue-dark;
}

/* Legal modal */
ngb-modal-window.general-modal .legal-modal .modal-content h4 {
  @apply p-8;
}

ngb-modal-window.general-modal .legal-modal .modal-content p {
  @apply mb-4;
}

ngb-modal-window.general-modal .legal-modal .modal-content .submit-btn {
  @apply mt-2;
}

/* General buttons */
button:hover {
  @apply opacity-80;
}

a:hover {
  @apply opacity-80;
}

/* General Swal Modal */
.swal2-container.swal2-shown {
  z-index: 1600;
}
.swal2-popup {
  background: transparent;
}

.swal2-popup.general-modal {
  @apply p-8 bg-body-light dark:bg-body-dark rounded-t-md;
}

.swal2-container.swal2-shown {
  z-index: 1101;
}

.swal2-popup.general-modal .swal2-content,
.swal2-popup.general-modal .swal2-title {
  @apply text-body-dark dark:text-body-light;
}

.swal2-popup.general-modal .swal2-title {
  @apply text-3.5xl mb-6;
}
.swal2-popup.attention-modal {
  @apply w-136;
}
.swal2-popup.attention-modal .swal2-actions .swal2-confirm {
  @apply w-44;
}

.swal2-popup.general-modal .swal2-content a,
.swal2-popup.general-modal .swal2-footer a {
  @apply text-blue-dark;
}

.swal2-popup.general-modal #swal2-content {
  @apply m-0 text-base;
}

.swal2-popup.general-modal .swal2-actions {
  @apply mt-6 mb-0;
}

.swal2-popup.general-modal .swal2-actions button {
  @apply relative w-32 m-0 text-base font-bold text-white rounded-full h-14 bg-blue-dark;
}

.swal2-popup.general-modal .swal2-footer {
  @apply p-0 m-0 mt-6 border-0;
}

.swal2-popup.general-modal swal2-image {
  @apply mt-0 mb-6;
}

/* Rewards detail modal */
.swal2-modal.rewards-detail-modal {
  @apply bg-body-light dark:bg-body-dark shadow-mediumModalShadow rounded-2xl;
}
.swal2-modal.rewards-detail-modal h1,
.swal2-modal.rewards-detail-modal p {
  @apply text-body-dark dark:text-body-light;
}

.swal2-modal.rewards-detail-modal .current-item span {
  @apply text-body-dark dark:text-body-light;
}

.swal2-modal.rewards-detail-modal a {
  border: none !important;
  color: #3589f9 !important;
}

.back-arrow {
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type='checkbox'].styled-checkbox {
  @apply hidden cursor-pointer;
}

input[type='checkbox'].styled-checkbox + label:before {
  content: '';
  -webkit-appearance: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  @apply relative inline-block p-2 mr-1 align-middle bg-transparent border cursor-pointer opacity-80 border-body-dark dark:border-body-light;
}

input[type='checkbox'].styled-checkbox:checked + label:before {
  @apply opacity-100 bg-blue-dark border-blue-dark;
}
input[type='checkbox'].styled-checkbox:checked + label:after {
  content: '\f00c';
  font-family: FontAwesome;
  top: 2px;
  left: 3px;
  @apply absolute block text-xs text-white border-blue-dark;
}

/* styles for ngx-datatable */
.ngx-datatable.material {
  @apply bg-box-light dark:bg-black-light;
  border-radius: 10px !important;
}

.full-experience-modal.modal {
  @apply flex items-center justify-center bg-white opacity-100 max-h-screen  max-w-144;
  min-height: unset;
  -webkit-animation: none;
  animation: none;
}

ngb-modal-window.full-experience-modal.modal {
  @apply overflow-hidden bg-white rounded-2xl;
}

/* Preview Styles */
body.modal-open.isPreview {
  overflow: auto;
}
ngb-modal-window .rewards-modal {
  max-height: 100% !important;
}

ngb-modal-window.general-modal div.reachGoal-modal div.modal-content {
  height: auto;
  padding: 0;
  text-align: center;
}

/* External Styles */
body.modal-open.isExternal {
  overflow: auto;
}

/* Ngx-Scrollbar Custom styles*/
.ngx-custom-scrollbar {
  background-color: #ccc !important;
  border-radius: 4px;
}
.ngx-custom-scrollbar-thumb {
  background-color: #808080 !important;
}
.ng-scrollbar-wrapper {
  direction: inherit;
}

/* This rule is gonna fix one bug on the library, 
  if the container has dynamic heigh the scrollbar appears when it shouldn't
  https://github.com/MurhafSousli/ngx-scrollbar/issues/260
*/
.ng-scroll-content {
  vertical-align: middle;
  display: inline-block;
}

.align-sub {
  vertical-align: sub;
}
